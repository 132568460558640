@tailwind base;

@layer base {

    @font-face {
        font-family: 'Spoon';
        font-style: normal;
        font-weight: 300;
        src: url('../fonts/spoon/spoon_regular-webfont.woff2') format('woff2'), url('../fonts/spoon/spoon_regular-webfont.woff') format('woff');
    }

    @font-face {
        font-family: 'Spoon';
        font-style: normal;
        font-weight: 500;
        src: url('../fonts/spoon/spoon_semibold-webfont.woff2') format('woff2'), url('../fonts/spoon/spoon_semibold-webfont.woff') format('woff');
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 300;
        src: url('../fonts/inter/inter-v11-latin-300.woff2') format("woff2");
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        src: url('../fonts/inter/inter-v11-latin-regular.woff2') format("woff2");
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        src: url('../fonts/inter/inter-v11-latin-500.woff2') format("woff2");
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        src: url('../fonts/inter/inter-v11-latin-700.woff2') format("woff2");
    }

    :root {
        --dann-primary: theme('colors.crusta.500');
        --dann-secondary: theme('colors.mariner.500');
        --dann-body-bg: theme('colors.white');
        --dann-body-color: theme('colors.gray.600');
        --dann-navbar-height-lg: 64px;
        --dann-sidebar-width-lg: 240px;
        --dann-footer-height-lg: 64px;
    }

    ::selection {
        color: theme('colors.white');;
        background: theme('colors.crusta.500');;
    }

    html {
        @apply h-full bg-white;
    }
    
    body {
    /*body > div {*/
        @apply h-full h-screen font-sans antialiased text-gray-600;
    }

    /* TODO: Add extra container */
    #__next {
        @apply h-full flex flex-col
    }

    h1, h2, h3 {
        @apply text-mariner-500 transition-all duration-200;
        font-family: 'Spoon', system-ui, sans-serif;
    }

    h4, h5, h6 {
        @apply font-bold;
    }

    h1 {
        @apply text-4xl font-medium;
    }

    h2 {
        @apply text-xl sm:text-2xl md:text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    p {
        @apply text-gray-600;
    }

    a {
        @apply text-crusta-500 transition-all duration-200 ease-in-out hover:underline hover:text-crusta-700;
    }

    nav a {
        @apply no-underline hover:no-underline;
    }

    input {
        @apply active:placeholder:opacity-0 focus:placeholder:opacity-0 placeholder:transition placeholder:duration-200 placeholder:ease-linear
    }

    [multiple], [type=date], [type=datetime-local], [type=email], [type=month], [type=number], [type=password],
    [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], select, textarea {
        @apply px-3 py-2 text-sm leading-6 placeholder-gray-400 bg-white rounded-none border border-gray-300 ring-0 appearance-none ease-linear duration-200 focus:ring-mariner-500
        focus:border-transparent focus:transition
        active:placeholder:opacity-0 focus:placeholder:opacity-0;
    }

    [type='checkbox']:focus, [type='radio']:focus {
        @apply ring-0 shadow-none ring-offset-0;
    }

    .hide-scroll-bar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .hide-scroll-bar::-webkit-scrollbar {
        display: none;
    }
    /* clears the ‘X’ from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }
}

@tailwind components;

@layer components {
    [class*='line-clamp-'] {
        display: -webkit-box;
        overflow: hidden;

        -webkit-box-orient: vertical;
    }
    .line-clamp-2 {
        -webkit-line-clamp: 2;
    }
    .line-clamp-3 {
        -webkit-line-clamp: 3;
    }
    .line-clamp-4 {
        -webkit-line-clamp: 4;
    }

    .hyphens-auto {
        hyphens: auto;
    }
}

@tailwind utilities;


body {
    --toastify-color-success: theme('colors.green.500');
    --toastify-text-color-success: theme('colors.green.100');
}
